import * as React from 'react';

import {
  BooleanField,
  BooleanInput,
  DateField,
  Edit,
  Form,
  Labeled,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
  Toolbar,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { Customer, Order } from '../../types';

import Basket from './Basket';
import { Link as RouterLink } from 'react-router-dom';
import Totals from './Totals';

const OrderEdit = () => (
  <Edit title={<OrderTitle />} component="div">
    <OrderForm />
  </Edit>
);

const OrderTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<Order>();
  return record ? (
    <span>
      {translate('resources.commands.title', {
        reference: record.reference,
      })}
    </span>
  ) : null;
};

const CustomerDetails = () => {
  const record = useRecordContext<Customer>();
  return (
    <div>
      <Typography
        component={RouterLink}
        color="primary"
        to={`/customers/${record?.id}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.name} {record?.phone}
      </Typography>
      <Typography
        component={Link}
        color="primary"
        href={`mailto:${record?.email}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.email}
      </Typography>
    </div>
  );
};

const CustomerPayment = () => {
  const record = useRecordContext<Customer>();
  const translate = useTranslate();
  return (
    <div>
      <BooleanInput row={true} source="makePaid" label="Оплачен" />
    </div>
  );
};

const ShippingInfo = () => {
  const record = useRecordContext<Customer>();
  const { shippingInfo } = record;
  return (
    <div>
      <Typography>
        {shippingInfo?.lastName} {shippingInfo?.firstName}{' '}
        {shippingInfo?.patronymic}
      </Typography>
      <Typography>{shippingInfo?.phoneNumber || ''}</Typography>
      <Typography>{shippingInfo?.city}</Typography>
      <Typography>
        {shippingInfo?.post},{' '}
        {shippingInfo?.province} {'|'} {shippingInfo?.city}, {shippingInfo?.street} {shippingInfo?.streetNumber} {shippingInfo?.zipCode},
        {shippingInfo?.type === 'courier'
          ? shippingInfo?.courierAddress
          : shippingInfo?.postNumber}
      </Typography>
    </div>
  );
};

const CustomerAddress = () => {
  const record = useRecordContext<Customer>();
  return (
    <Box>
      <Typography>
        {record?.name} {record?.phone}
      </Typography>
      <Typography>{record?.address?.city}</Typography>
      <Typography>
        {record?.address?.post},{' '}
        {record?.address?.type === 'courier'
          ? record?.address?.courierAddress
          : record?.address?.postNumber}
      </Typography>
    </Box>
  );
};

const CustomerBonus = () => {
  const record = useRecordContext<Customer>();
  const { nextBonus, bonusAdded } = record;
  return (
    <div>
      <Typography>
        {nextBonus}€. ({bonusAdded ? 'Активен' : 'Не активен'})
      </Typography>
    </div>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderForm = () => {
  const record = useRecordContext<Order>();
  const translate = useTranslate();
  return (
    <Form>
      <Box maxWidth="50em">
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  {translate('resources.commands.section.order')}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="date" resource="commands" label="Дата">
                      <DateField showTime source="date" resource="commands" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled
                      source="orderId"
                      resource="commands"
                      label="№ заказа"
                    >
                      <TextField source="orderId" resource="commands" />
                    </Labeled>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <SelectInput
                      resource="commands"
                      source="status"
                      label="Статус"
                      validate={[required()]}
                      choices={[
                        {
                          id: 'ordered',
                          name: 'Заказан',
                        },
                        {
                          id: 'delivered',
                          name: 'Доставлен',
                        },
                        {
                          id: 'cancelled',
                          name: 'Отменен',
                        },
                      ]}
                    />
                    <Box mt={2}>
                      <Labeled
                        source="orderId"
                        resource="commands"
                        label={translate(
                          'resources.commands.section.next_bonus'
                        )}
                      >
                        <CustomerBonus />
                      </Labeled>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box mt={2}>
                      <BooleanInput
                        row={true}
                        resource="commands"
                        source="returned"
                        label="Возврат"
                      />
                    </Box>
                  </Grid>
                  {record.shippingMethod !== 'local' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        source="trackNumber"
                        resource="commands"
                        label="Трек номер"
                      >
                        <TextInput
                          source="trackNumber"
                          resource="commands"
                          label="Трек номер"
                        />
                      </Labeled>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  {translate('resources.commands.section.customer')}
                </Typography>
                <ReferenceField
                  source="clientId"
                  resource="commands"
                  reference="customers"
                  // basePath="/customers"
                  link={false}
                >
                  <CustomerDetails />
                </ReferenceField>
                <Spacer />

                <Typography variant="h6" gutterBottom>
                  {translate('resources.commands.section.shipping_address')}
                </Typography>
                {record.shippingMethod === 'local' ? (
                  <TextField emptyText="Самовывоз" resource="commands" />
                ) : new Date(record.date) >
                  new Date('2021-10-28T00:00:00.159+00:00') ? (
                  <ShippingInfo />
                ) : (
                  <ReferenceField
                    source="clientId"
                    resource="commands"
                    reference="customers"
                    // basePath="/customers"
                    link={false}
                  >
                    <CustomerAddress />
                  </ReferenceField>
                )}
                <Typography variant="h6" gutterBottom>
                  {translate('resources.commands.section.payment')}
                </Typography>
                <BooleanField source="payment" resource="commands" />
                {record.payment === true ? (
                  <>
                    <DateField
                      source="paymentDate"
                      resource="commands"
                      showTime
                    />
                    &nbsp;
                    <NumberField
                      sx={{ color: 'green', fontWeight: 'bold' }}
                      source="paymentSumm"
                      resource="commands"
                      options={{
                        style: 'currency',
                        currency: 'PLN',
                        fontWeight: 'bold',
                      }}
                    />
                    &nbsp;
                    (<NumberField
                      sx={{ color: 'green', fontWeight: 'bold' }}
                      source="totalSummInUSD"
                      resource="commands"
                      options={{
                        style: 'currency',
                        currency: 'EUR',
                        fontWeight: 'bold',
                      }}
                    />)
                    &nbsp;
                    <Labeled
                      source="paymentMethod"
                      resource="commands"
                      label="Способ оплаты"
                    >
                      {record.paymentMethod === 'admin' ? (
                        <TextField
                          emptyText="Админ панель"
                          resource="commands"
                        />
                      ) : (
                        <TextField source="paymentMethod" resource="commands" />
                      )}
                    </Labeled>
                  </>
                ) : (
                  <ReferenceField
                    source="clientId"
                    resource="commands"
                    reference="customers"
                    // basePath="/customers"
                    link={false}
                  >
                    <CustomerPayment />
                  </ReferenceField>
                )}
              </Grid>
            </Grid>
            <Spacer />

            <Typography variant="h6" gutterBottom>
              {translate('resources.commands.section.items')}
            </Typography>
            <Box>
              <Basket />
            </Box>
            <Spacer />

            <Typography variant="h6" gutterBottom>
              {translate('resources.commands.section.total')}
            </Typography>
            <Box>
              <Totals />
            </Box>
          </CardContent>
          <Toolbar />
        </Card>
      </Box>
    </Form>
  );
};

export default OrderEdit;
